import logo from "../../assets/imgs/GIRLS&CRYSTALS_PWA_LOGO.webp";
import item1 from "../../assets/imgs/GIRLS&CRYSTALS_PWA_1.webp";
import item2 from "../../assets/imgs/GIRLS&CRYSTALS_PWA_2.webp";
import item3 from "../../assets/imgs/GIRLS&CRYSTALS_PWA_3.webp";
import blueStar from "../../assets/starBlue.png";
import greyStar from "../../assets/starGrey.png";
import user from "../../assets/user.png";
import {useEffect, useState} from "react";
import {Reviews} from "../Reviews/Reviews";
import {New} from "../New/New";
import {Additional} from "../Additional/Additional";
import {ColorRing} from "react-loader-spinner";
import UAParser from "ua-parser-js";
import {useReactPWAInstall} from "react-pwa-install";
// import { useReactPWAInstall } from "react-pwa-install";

export const Main = ({
                         handleClick,
                         animating,
                         setAnimating,
                         megabyte,
                         isSupported,
                     }) => {
    const [showDescription, setShowDescription] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // const { supported, isInstalled } = useReactPWAInstall();

    const {supported} = useReactPWAInstall();

    const creative_id = localStorage.getItem("creative_id");
    const offer = localStorage.getItem("offer");
    const ad_campaign_id = localStorage.getItem("ad_campaign_id");
    const source = localStorage.getItem("source");
    const creo_id = localStorage.getItem("creo_id");
    const external_id = localStorage.getItem("external_id");
    const site_id = localStorage.getItem("site_id");
    const cost = localStorage.getItem("cost");
    const sub_id_1 = localStorage.getItem("sub_id_1");
    const sub_id_2 = localStorage.getItem("sub_id_2");
    const sub_id_3 = localStorage.getItem("sub_id_3");
    const id = localStorage.getItem("id");

    useEffect(() => {
        const isClickedInstall = localStorage.getItem("isClickedInstall");
        if (isClickedInstall) {
            localStorage.removeItem("isClickedInstall");
            if (id === "1092") {
                window.location.href =
                    "https://arumo.top/4JGY2twS?a=b" +
                    (external_id !== null ? `&external_id=${external_id}` : "") +
                    (creo_id !== null ? `&creo_id=${creo_id}` : "") +
                    (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
                    (site_id !== null ? `&site_id=${site_id}` : "");
            } else if (id === "1091") {
                window.location.href =
                    "https://arumo.top/Wq3G1mzH?a=b" +
                    (creative_id !== null ? `&creative_id=${creative_id}` : "") +
                    (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
                    (source !== null ? `&source=${source}` : "");
            } else if (id === "1096") {
                window.location.href =
                    "https://arumo.top/WChY78t3?a=b" +
                    (external_id !== null ? `&external_id=${external_id}` : "") +
                    (creo_id !== null ? `&creative_id=${creo_id}` : "") +
                    (site_id !== null ? `&site_id=${site_id}` : "") +
                    (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "");
            } else if (id === "1100") {
                window.location.href =
                    "https://arumo.top/DVFjchQ9?a=b" +
                    (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "");
            } else {
                window.location.href =
                    "https://arumo.top/4JGY2twS?a=b" +
                    (external_id !== null ? `&external_id=${external_id}` : "") +
                    (creo_id !== null ? `&creo_id=${creo_id}` : "") +
                    (ad_campaign_id !== null ? `&ad_campaign_id=${ad_campaign_id}` : "") +
                    (site_id !== null ? `&site_id=${site_id}` : "");
            }
        }
    }, []);

    const ifWebView = () => {
        const ua = new UAParser();
        const urlParams = new URLSearchParams(window.location.search);

        if (
            ua.getBrowser().name !== "Chrome" &&
            ua.getBrowser().name !== "Yandex"
        ) {
            // alert(window.location.hostname);
            // alert("test8")
            var lnk = document.getElementById("r");
            // lnk.setAttribute("href", `intent://${window.location.hostname}/?#Intent;scheme=https;package=com.android.chrome;end;`);
            lnk.setAttribute(
                "href",
                `intent://${window.location.hostname}/?sub_id_1=${
                    sub_id_1 ? sub_id_1 : ""
                }&sub_id_2=${sub_id_2 ? sub_id_2 : ""}&sub_id_3=${
                    sub_id_3 ? sub_id_3 : ""
                }#Intent;scheme=https;package=com.android.chrome;end;`
            );

            lnk.click();

            // lnk.click();
            if (ua.getBrowser().name == "Chrome WebView") {
                //goLink();
            }
        } else {
            handleClick();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);

    return (
        <div className="main__top">
            <main className="main">
                <div className="main__wrapper">
                    <img src={logo} alt="logo" className="logo"/>
                    <div className="main__wrap">
                        <p className="main__title">NBU Casino</p>
                        <div className="main__tags">
                            <p className="main__tag">NBU </p>
                            <p className="main__tag">Casino</p>
                        </div>
                        <p className="years">21+</p>
                    </div>
                    <div className="desktop__info">
                        <div className="top__wrapper">
                            <img src={blueStar} alt="safety" width={14} height={14}/>
                            <p className="top__text">Muharrir tanlovi</p>
                        </div>
                        <div className="bottom__wrapper">
                            <div className="greyStars__wrapper">
                                <img src={greyStar} alt="grey star" width={10} height={10}/>
                                <img src={greyStar} alt="grey star" width={10} height={10}/>
                                <img src={greyStar} alt="grey star" width={10} height={10}/>
                                <img src={greyStar} alt="grey star" width={10} height={10}/>
                                <img src={greyStar} alt="grey star" width={10} height={10}/>
                            </div>
                            <p className="bottom__text">2697</p>
                            <img src={user} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="app__info">
                    <ul className="app__list">
                        <li className="app__item">
                            <div className="app__content">
                                <div className="app__wrapper">
                                    <p className="app__title">4.8</p>
                                    <svg
                                        width={14}
                                        enableBackground="new 0 0 24 24"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="#fff"
                                    >
                                        <g fill="none">
                                            <path d="M0,0h24v24H0V0z"></path>
                                            <path d="M0,0h24v24H0V0z"></path>
                                        </g>
                                        <path
                                            d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z"></path>
                                    </svg>
                                </div>
                                <p className="app__description">Reyting</p>
                            </div>
                        </li>
                        <li className="app__item">
                            <div className="app__content">
                                <p className="app__title">
                                    <svg
                                        viewBox="0 0 18 18"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        fill="#fff"
                                    >
                                        <g transform="translate(21.552 22.5) rotate(180)">
                                            <path
                                                transform="translate(7.552 7.652)"
                                                d="M.625,0h8.75A.68.68,0,0,1,10,.723a.68.68,0,0,1-.625.723H.625A.68.68,0,0,1,0,.723.68.68,0,0,1,.625,0Z"
                                                data-name="Path 288"
                                            ></path>
                                            <g transform="translate(17.552 20.797) rotate(180)">
                                                <path
                                                    d="M0,0H9.666V9.666H0Z"
                                                    fill="none"
                                                    data-name="Path 289"
                                                ></path>
                                                <path
                                                    transform="translate(-4.408 -3.203)"
                                                    d="M8.752,4.642V11.81L5.536,8.678a.677.677,0,0,0-.936,0,.627.627,0,0,0,0,.9l4.343,4.229a.669.669,0,0,0,.929,0l4.343-4.229a.627.627,0,0,0,0-.9.669.669,0,0,0-.929,0L10.07,11.81V4.642a.659.659,0,0,0-1.318,0Z"
                                                    data-name="Path 290"
                                                ></path>
                                            </g>
                                            <rect
                                                transform="translate(4.552 5.5)"
                                                width="16"
                                                height="16"
                                                rx="2"
                                                fill="none"
                                                stroke="#fff"
                                                strokeWidth="2"
                                                data-name="Rectangle 123"
                                            ></rect>
                                        </g>
                                    </svg>
                                </p>
                                <p className="app__description">3.3MB</p>
                            </div>
                        </li>
                        <li className="app__item">
                            <div className="app__content">
                                <p className="app__title">21+</p>
                                <p className="app__description">Yosh</p>
                            </div>
                        </li>
                        <li className="app__item">
                            <div className="app__content">
                                <p className="app__title">20794</p>
                                <p className="app__description">Oʻrnatilgan</p>
                            </div>
                        </li>
                    </ul>
                </div>
                {animating === true ? (
                    <div className="lines__wrapper">
                        {megabyte === "Installing..." ? (
                            <p className="megabytes">{megabyte}</p>
                        ) : (
                            <p className="megabytes">{megabyte} MB / 3.3 MB</p>
                        )}
                        <div className="greyLine">
                            <div className="greenLine"></div>
                        </div>
                    </div>
                ) : animating === "done" ? (
                    <div
                        onClick={() => {
                            localStorage.setItem("isClickedInstall", true);
                        }}
                    >
                        <a href="/" target="_blank" className="main__button pwa__link">
                            O'ynang
                        </a>
                    </div>
                ) : isSupported ? (
                    <button
                        type="button"
                        className="main__button"
                        disabled={isLoading}
                        onClick={() => {
                            handleClick();
                        }}
                    >
                        {isLoading ? (
                            <ColorRing
                                visible={true}
                                height="27"
                                width="27"
                                ariaLabel="color-ring-loading"
                                wrapperStyle={{}}
                                wrapperClass="color-ring-wrapper"
                                colors={["#ffffff", "#ffffff", "#ffffff", "#ffffff", "#ffffff"]}
                            />
                        ) : (
                            "O'rnatish"
                        )}
                        {/* {supported() && !isInstalled() ? "Install" : "Play"} */}
                    </button>
                ) : (
                    <a
                        href="/"
                        target="_blank"
                        className="main__button pwa__link"
                        onClick={() => {
                            // ifWebView();
                            localStorage.setItem("isClickedInstall", true);
                        }}
                    >
                        Играть
                    </a>
                )}
                <div className="main__thumb">
                    <img
                        src={item1}
                        alt="app view"
                        height={210}
                        className="main__images"
                    />
                    <img
                        src={item2}
                        alt="app view"
                        height={210}
                        className="main__images"
                    />
                    <img
                        src={item3}
                        alt="app view"
                        height={210}
                        className="main__images"
                    />
                </div>
                <p className="main__about">Ushbu ilova haqida</p>
                <p className="main__item--one">
                    NBU Casino - bu kazino dunyosidagi turli xil o'yinlar va yangi mahsulotlarni o'z ichiga olgan super dastur!
                </p>
                <ul
                    className={
                        showDescription ? "main__list" : "main__list main__list--blur"
                    }
                >
                    <li className="main__item">
                        O'zbekistonning eng ishonchli va qonuniy kazinosi 2024!
                    </li>
                    {showDescription && (
                        <>
                            <li className="main__item">
                                Darhol depozit va pul mablag'larini yechib olish, komissiyasiz
                            </li>
                            <li className="main__item">
                                Minimal depozit atigi 15 000 soʻm
                            </li>
                            <li className="main__item">
                                Barcha sevimli slotlaringiz bir joyda
                            </li>
                            <li className="main__item">
                                Tez-tez musobaqalar va lotereyalar
                            </li>
                            <li className="main__item">24/7 texnik yordam</li>
                            <li className="main__item">
                                O'zbekistondagi barcha kazinolar orasida eng yaxshi loyiha!
                            </li>
                            <p
                                className="main__item"
                                style={{marginLeft: "-15px", marginBottom: "20px"}}
                            >
                                В новое Казино играй - легкие деньги получай!
                            </p>
                            <p className="main__item" style={{marginLeft: "-15px"}}>
                                Yangi Casino o'ynang - oson pul oling!
                            </p>
                            <li className="main__item">
                                Yangi o'yinchilar uchun 4 000 000 so'm BONUS!
                            </li>
                            <li className="main__item">
                                1 000 000 000 soʻm oʻyinida bepul ishtirok etish!
                            </li>
                            <li className="main__item">
                                250 FS (Bepul aylantirish) - omad g'ildiragini aylantiring
                            </li>
                            <li className="main__item">
                                O'yinchilarga turli xizmatlarni to'lash uchun bonus ballarini berish
                            </li>
                             <li className="main__item">
                                 Birinchi depozit bonusi 150% - ro'yxatdan o'tgandan keyin 30 kun davomida amal qiladi
              </li>
                        </>
                    )}
                </ul>
                <button
                    type="button"
                    className="show__button"
                    onClick={() => {
                        setShowDescription((prevState) => !prevState);
                    }}
                >
                    {showDescription ? "Ozroq" : "Ko'proq..."}
                </button>
                <div className="line"></div>
                <Reviews/>
                <div className="line"></div>
                <New/>
                <div className="line"></div>
                <Additional/>
                <div className="line"></div>
            </main>
            <a style={{opacity: "0"}} href="#" id="r">
                test
            </a>
        </div>
    );
};
